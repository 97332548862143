
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import routes from './routes/router';
import Swal from "sweetalert2";
import { myContext } from './utils/contextApi/context';
import request from './utils/request'
import toast from 'react-hot-toast';
import { FaInfoCircle } from 'react-icons/fa';
import ReactDOM from 'react-dom';
import moment from 'moment';
import axios from 'axios';
import VideoPlayBox from './components/VideoPlayBox';

function App() {
  const [listening, setListening] = useState(false);
  const [data, setData] = useState([]);
  let eventSource = undefined;
  const storedData = localStorage.getItem("userData");
  const parsedData = JSON.parse(storedData);
  const [item, setItem] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const userID = parsedData?.user?.id;
  const { unReadCount, setUnReadCount, notifications, setNotifications } = useContext(myContext)
  const [openPlayBox, setOpenPlayBox] = useState(false);
  const navigate = useNavigate()
  const selectedCameras = JSON.parse(localStorage.getItem('selectedCameras')) || []
  const handleClose = () => {
    setOpenPlayBox(false)
  }

  const handleSMS = (id) => {
    request({
      url: `${process.env.REACT_APP_API_URL}/sms/send-sms/${id}`,
      method: "POST"
    }).then((reponse) => {
      toast.success("SMS sended successfully")
    }).catch((err) => {
      toast.error(err?.response?.data?.error || err?.response?.data?.message || err?.response?.data?.body || 'Something went wrong');
    })
  }

  const handlePush = (id) => {
    request({
      url: `${process.env.REACT_APP_API_URL}/notifications/send/${id}`,
      method: "POST"
    }).then((reponse) => {
      toast.success("Notification sended successfully")
    }).catch((err) => {
      toast.error(err?.response?.data?.error || err?.response?.data?.message || err?.response?.data?.body || 'Something went wrong');
    })
  }

  const fetchNotifications = () => {
    if(userData?.user?.id){
      request({
        url: `http://103.66.79.27:18080/api/notifications/user/${userData?.user?.id}`,
        method: "GET"
      }).then((response) => {
        setNotifications(response)
      }).catch((err) => {
        toast.error(err?.response?.data?.error || err?.response?.data?.message || err?.response?.data?.body || 'Something went wrong');
      })
    }
  }

  const fetchUnReadCount = () => {
    if(userData?.user?.id){
      request({
        url: `http://103.66.79.27:18080/api/notifications/user/${userData?.user?.id}/unread-count`,
        method: "GET"
      }).then((response) => {
        setUnReadCount(response)
      }).catch((err) => {
        toast.error(err?.response?.data?.error || err?.response?.data?.message || err?.response?.data?.body || 'Something went wrong');
      })
    }
  }

  useEffect(() => {
    fetchNotifications()
    fetchUnReadCount();
  }, [])

  useEffect(() => {

    //console.log('listening'+listening);
    if (!listening && userID) {

      eventSource = new EventSource(`http://103.66.79.27:18080/api/notifications/user/${userID}/stream`);

      eventSource.addEventListener("NOTIFICATION", (event) => {
        const parsedEventData = JSON.parse(event.data);
        setData(old => [...old, parsedEventData]);
        fetchNotifications();
        fetchUnReadCount();
      });

      eventSource.onerror = (event) => {
        console.log(event.target.readyState)
        if (event.target.readyState === EventSource.CLOSED) {
          console.log('SSE closed (' + event.target.readyState + ')')
        }
        eventSource.close();
      }

      eventSource.onopen = (event) => {
        console.log("connection opened")
      }
      setListening(true);

    }

  }, [userID])

  useEffect(() => {
    let userData1 = JSON.parse(localStorage.getItem('userData'));
    const fetchImage = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/detection/download/${data[data.length - 1]?.imageFilename}`, { responseType: 'blob', headers: { Authorization: userData1 ? 'Bearer ' + userData1.authToken : null } });
        const url = URL.createObjectURL(response.data);
        setBlobUrl(url);
        setItem(data[data.length - 1])
      } catch (err) {
        setItem(data[data.length - 1])
        toast.error('Failed to fetch image');
      }
    };
    if (data[data.length - 1]?.imageFilename) {
      fetchImage();
    }
  }, [data])

  useEffect(() => {
    if (openPlayBox) {
      request({
        url: `http://103.66.79.27:18080/api/notifications/user/${userData?.user?.id}/mark-seen`,
        method: "POST",
        data: [item?.id]
      }).then((response) => {
        fetchNotifications();
        fetchUnReadCount();
      }).catch((err) => {
        toast.error(err?.response?.data?.error || err?.response?.data?.message || err?.response?.data?.body || 'Something went wrong');
      })
    }
  }, [openPlayBox])


  useEffect(() => {
    console.log(item)
    console.log(selectedCameras?.some(id=>id == item?.cameraId))
    if (item && !selectedCameras?.some(id=>id== item?.cameraId)) {
      const latestData = item;
      const message = `${latestData.description} detected`;

      const iconContainer = document.createElement('div');

      // Create the HTML structure
      const htmlContent = `
        <div style="text-align: center;">
          <div id="iconContainer" style="margin-bottom: 10px;"></div>
          <h3 style="margin-bottom:20px;">${message}</h3>
          <div style="display: flex;  margin-top: 10px; padding:5px; border-radius:10px; background-color:  #f2f2f2";>
            <div>
              <img style="width: 100px; height: 90px; margin-right: 10px; border-radius:10px; cursor:pointer" src=${blobUrl} alt="Image" id="notificationImage" />
            </div>
            <div>
              <p style=" margin-bottom:3px; text-align:start">${latestData.cameraName}</p>
              <p style="margin-bottom:3px; text-align:start">${latestData.description}</p>
              <p style="text-align:start">${moment(latestData.detectedTime).format('DD-MM-YYYY hh:mm A')}</p>
            </div>
          </div>
          <div style="margin-top: 10px; text-align:start">
            <button id="sendSmsButton" class="swal2-confirm swal2-styled" style="margin-right: 5px; background-color:green; font-size:15px; font-weight:400">Send SMS</button>
            <button id="sendPushButton" class="swal2-confirm swal2-styled" style="background-color:green; font-size:15px; font-weight:400;">Send Push</button>
          </div>
        </div>
      `;

      Swal.fire({
        html: htmlContent,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: ' Ok ',
        cancelButtonText: 'View',
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          // Action when the "View" button (cancel) is clicked
          navigate(`/admin/camera-detection-videos/${item?.cameraId}`);
        } else if (result.isConfirmed) {
          // Close the popup when the "Ok" button is clicked
          Swal.close();  // This will just close the SweetAlert2 modal
        }
      });

      ReactDOM.render(<FaInfoCircle style={{ fontSize: '50px' }} />, iconContainer);
      document.getElementById('iconContainer').appendChild(iconContainer);

      document.getElementById('notificationImage').onclick = () => {
        setOpenPlayBox(true);
      };

      document.getElementById('sendSmsButton').onclick = () => {
        handleSMS(item?.detectionId)
        // Swal.close();
      };

      document.getElementById('sendPushButton').onclick = () => {
        handlePush(item?.detectionId)
        // Swal.close();
      };
    };

  }, [item]);

  if (localStorage.getItem('userData') === 'undefined') {
    localStorage.clear();
  }

  const userData = JSON.parse(localStorage.getItem('userData'));
  const tokenValue = userData ? userData.authToken : '';
  const routing = useRoutes(routes(tokenValue));

  return (
    <div>
      {routing}
      {openPlayBox && <VideoPlayBox handleClose={handleClose} data={item} />}
    </div>
  );
}

export default App;

