import React from 'react'
import { Toaster } from 'react-hot-toast'
import { Link } from 'react-router-dom'

function Breadcrub({ pageTitle, parentLink = '', child = false ,setIsVisible,isVisible  }) {

    return (
        <>
            <Toaster />
            <div className="pagetitle">
                <div className='d-flex justify-content-between align-items-center'>
                    <h1>{pageTitle}</h1>
                </div>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                        {parentLink && <li className="breadcrumb-item">{parentLink}</li>}
                        <li className="breadcrumb-item">{pageTitle}</li>
                    </ol>
                </nav>

            </div>
        </>
    )
}

export default Breadcrub