import axios from 'axios';
// create an axios instance
const service = axios.create({
    baseURL: process.env.REACT_APP_API_URL // url = base url + request url
    // timeout: 5000 // request timeout
  });
  // request interceptor do something before request is sent
service.interceptors.request.use(
    (config) => {
      var userData = JSON.parse(localStorage.getItem('userData'));
      config.headers = {
        'content-type': 'application/json',
        Accept: 'application/json',
        Authorization: userData ? 'Bearer ' + userData.authToken : null
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // response interceptor
service.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      if (error.response.status === 401) {       
        let userType = localStorage.getItem('userType');
        if(userType=='admin'){
          localStorage.clear();
          window.location.href = '/login';
          window.location.reload()
        }else{
          localStorage.clear();
          window.location.href = '/login';
          window.location.reload()
        }
      }
      return Promise.reject(error);
    }
  );
  
  export default service;