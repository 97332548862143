import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SidebarLayout from '../layout/SidebarLayout';
import UserLayout from '../layout/UserLayout';
import PauseNotifications from '../pages/Settings/PauseNotifications';

const Loader = (Component) => (props) =>
(
    <Suspense >
        <Component {...props} />
    </Suspense>
);
const LoginPage = Loader(lazy(() => import('../pages/LoginPage')));
const ErrorPage = Loader(lazy(() => import('../pages/ErrorPage')));
const Dashboard = Loader(lazy(() => import('../pages/Dashboard')));
const UpdatePassword = Loader(lazy(() => import('../pages/UpdatePassword')));
const UpdateProfile = Loader(lazy(() => import('../pages/UpdateProfile')));
const DesignationList = Loader(lazy(() => import('../pages/Designation/List')));
const OfficeLocationList = Loader(lazy(() => import('../pages/OfficeLocation/List')));
const CameraLocationList = Loader(lazy(() => import('../pages/CameraLocation/List')));
const UserList = Loader(lazy(() => import('../pages/Users/List')));
const UserCreateEdit = Loader(lazy(() => import('../pages/Users/CreateEdit')));
const OfficeList = Loader(lazy(() => import('../pages/Offices/List')));
const OfficeCreateEdit = Loader(lazy(() => import('../pages/Offices/CreateEdit')));
const CameraList = Loader(lazy(() => import('../pages/Camera/List')));
const CameraCreateEdit = Loader(lazy(() => import('../pages/Camera/CreateEdit')));
const RolesList = Loader(lazy(() => import('../pages/Roles/List')));
const RolesCreateEdit = Loader(lazy(() => import('../pages/Roles/CreateEdit')));
const NoPermissionPage = Loader(lazy(() => import('../pages/NoPermissionPage')));
const CameraDetectionList = Loader(lazy(() => import('../pages/CameraDetection/CameraList')));
const CameraVideoList = Loader(lazy(() => import('../pages/CameraDetection/VideoList')));
const Notification = Loader(lazy(() => import('../pages/Notification')));




function External() {
    window.location.href = '/home.html';
    return null;
}

const routes = (isLoggedIn) => [
    {
        path: '/',
        element: isLoggedIn ? <Navigate to="/admin/dashboard" /> : <Navigate to="/login" />,

    },
    {
        path: '/notification',
        element: isLoggedIn ? <Notification /> : <Navigate to="/login" />,
    },
    {
        path: '/404',
        element: <ErrorPage />,

    },
    // {
    //     path: '/notification',
    //     element: <Notification />,

    // },
    {
        path: '/permission-denied',
        element: <NoPermissionPage />,

    },
    {
        path: '/login',
        element: <LoginPage />,

    },
    {
        path: '/login',
        element: <LoginPage />,

    },
    {
        path: '/admin',
        element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" />,
        children: [
            { element: <Navigate to="/admin/dashboard" replace /> },
            {
                path: "dashboard",
                element: <Dashboard />,
            },
            {
                path: "update-password",
                element: <UpdatePassword />,
            },
            {
                path: "update-profile",
                element: <UpdateProfile />,
            },
            {
                path: "designation-list",
                element: <DesignationList />,
            },
            {
                path: "office-location-list",
                element: <OfficeLocationList />,
            },
            {
                path: "camera-location-list",
                element: <CameraLocationList />,
            },
            {
                path: "user-list",
                element: <UserList />,
            },
            {
                path: "user-create",
                element: <UserCreateEdit />,
            },
            {
                path: "office-list",
                element: <OfficeList />,
            },
            {
                path: "office-create",
                element: <OfficeCreateEdit />,
            },
            {
                path: "camera-list",
                element: <CameraList />,
            },
            {
                path: "camera-detection",
                element: <CameraDetectionList />,
            },
            {
                path: "camera-detection-videos/:camera_id",
                element: <CameraVideoList />
            },

            {
                path: "camera-create",
                element: <CameraCreateEdit />,
            },
            {
                path: "roles-list",
                element: <RolesList />,
            },
            {
                path: "roles-create",
                element: <RolesCreateEdit />,
            },
            {
                path: "settings",
                element:<PauseNotifications />
            }
        ]
    },

    
  
    { path: '*', element: <Navigate to="/404" replace /> }
];

export default routes;