import React from 'react'

function BtnLoader() {
    return (
        <span>
            <img src='/assets/img/loader.svg'/>
        </span>
    )
}

export default BtnLoader