import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Breadcrub from '../../components/Breadcrub';
import BtnLoader from '../../components/BtnLoader';
import request from '../../utils/request';
import checkPermissions from '../../utils/helper';
import { useNavigate } from 'react-router-dom';

function PauseNotifications() {
    const navigate = useNavigate();
    if (!checkPermissions('notification-receive') ) {
        navigate('/permission-denied')
    }
    const [cameraList, setCameraList] = useState([]);
    const [selectedCameras, setSelectedCameras] = useState([]);
    const [loader, setLoader] = useState(false);
    let userData = JSON.parse(localStorage.getItem('userData'));
    // Fetch camera list when component mounts
    useEffect(() => {
        fetCameraList();
        // Check localStorage for previously selected cameras
        const savedCameras = JSON.parse(localStorage.getItem('selectedCameras')) || [];
        setSelectedCameras(savedCameras); // Set selected cameras from localStorage
    }, []);

    // Fetch camera list function
    const fetCameraList = () => {
        setLoader(true);
        request({
            url: `/cameras/c1/list?userId=${userData?.user?.id}&page=0&size=10000&search=&locationId=&officeId=`,
            method: 'GET',
        })
            .then((response) => {
                setCameraList(response?.content); // Set camera list
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                toast.error(err?.response?.data?.error || err?.response?.data?.message || 'Something went wrong');
            });
    };

    // Handle checkbox change
    const handleCheckboxChange = (cameraId, checked) => {
        if (checked) {
            setSelectedCameras((prevSelected) => [...prevSelected, cameraId]); // Add camera to selected
        } else {
            setSelectedCameras((prevSelected) => prevSelected.filter(id => id !== cameraId)); // Remove camera from selected
        }
    };

    // Handle submit
    const handleSubmit = () => {
        // Save selected cameras to localStorage
        localStorage.setItem('selectedCameras', JSON.stringify(selectedCameras));
        toast.success('Selected cameras saved successfully');
    };



    return (
        <main id="main" className="main">
            <Breadcrub pageTitle={'Settings'} />

            {/* Render camera list */}
            <div style={{ 'minHeight': '65vh' }} className='section bg-white px-3 mb-4'>
                <h5 className='card-title'>Pause Notifications From</h5>
                <div className='row gap-3'>
                    {cameraList.map((camera) => (
                        <div key={camera.id} className="camera-checkbox d-flex col-12">
                            <input
                                type="checkbox"
                                id={`camera-${camera.id}`}
                                checked={selectedCameras.includes(camera.id)} // Check if camera is selected
                                onChange={(e) => handleCheckboxChange(camera.id, e.target.checked)}
                            />&nbsp;&nbsp;
                            <label htmlFor={`camera-${camera.id}`}>{camera.name}, {camera?.location?.name}, {camera?.offices?.name}</label>
                        </div>
                    ))}
                    <button type="submit" className="btn btn-primary ms-2 mb-3" onClick={handleSubmit} disabled={loader} style={{ 'marginRight': '20px', 'minWidth': '100px',maxWidth:"120px", float: 'left', marginTop: "10px" }}>
                        {loader ? <BtnLoader /> : 'Save'}
                    </button>
                </div>
            </div>
        </main>
    );
}

export default PauseNotifications;
